import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { markdownConverter } from "../utils/markdown";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

const IndexPage = ({ data }) => {
  const remark = data.allMarkdownRemark.edges[0].node;
  const { frontmatter: pageData } = remark;
  return (
    <Layout title="Etusivu">
      <div className="page section pb-4">
        <div className="container front-page">
          <PageHeader
            title={pageData.pageTitle}
            description={pageData.pageDescription}
            image={pageData.pageImage}
          />
          <div className="front-page__hero-1">
            <div className="columns front-page__hero-1__columns">
              <div className="column is-5 front-page__hero-1__columns__left">
                <img src={pageData.heroImage1} alt="hero 1" />
                <blockquote className="mt">{pageData.heroQuote1}</blockquote>
              </div>
              <div className="column is-7 front-page__hero-1__columns__right text-container">
                <h2>{pageData.heroTitle1}</h2>
                <div
                  className="mb"
                  dangerouslySetInnerHTML={{
                    __html: markdownConverter.makeHtml(pageData.heroBody1),
                  }}
                ></div>
                <Link className="cta" to="/toimitilat">
                  Toimitilat
                </Link>
              </div>
            </div>
          </div>
          <div className="front-page__break mt-5">
            <img src={pageData.breakImage} alt="page break" />
          </div>
          <div className="front-page__hero-2">
            <div className="columns front-page__hero-2__columns">
              <div className="column is-5 front-page__hero-2__columns__left text-container">
                <div className="mt">
                  <h2>{pageData.heroTitle2}</h2>
                  <div
                    className="mb"
                    dangerouslySetInnerHTML={{
                      __html: markdownConverter.makeHtml(pageData.heroBody2),
                    }}
                  ></div>
                  <Link className="cta" to="/yrityksen-tarina">
                    Yrityksen tarina
                  </Link>
                </div>
              </div>
              <div className="column is-7 front-page__hero-2__columns__right">
                <img src={pageData.heroImage2} alt="hero 2" />
                <blockquote className="mt">{pageData.heroQuote2}</blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "front-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            templateKey
            pageDescription
            pageTitle
            pageImage
            heroTitle1
            heroBody1
            heroImage1
            heroQuote1
            breakImage
            heroTitle2
            heroBody2
            heroImage2
            heroQuote2
          }
        }
      }
    }
  }
`;
