import React from "react";

const PageHeader = ({ title, description, image, kauppapuisto }) => {
  return (
    <div className="page-header">
      <img
        className="page-header__background-image"
        src={image}
        alt="page header"
      />
      {!kauppapuisto && (
        <>
          <div className="page-header__gradient">

          </div>

          <div className="page-header__content">
            <div className="page-header__content__text">
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
            <div></div>
          </div>
        </>
      )}
    </div>
  );
};

export default PageHeader;
